import React from 'react';

// types
import type { WCmsWeddingRegistryPageView, WPublicThemeV2View } from '@zola/svc-web-api-ts-client';

// redux
import { useAppSelector } from 'reducers/useAppSelector';
import { getUserContext } from 'selectors/user/userSelectors';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import TitleImgDesc from 'components/publicWebsiteV2/common/TitleImgDesc';

// utils
import getNonHomeCmsHeroImg from 'components/publicWebsiteV2/util/getNonHomeCmsHeroImg';

// components
import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import { useAnimationContext } from 'components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationContext/AnimationContext';
import ExternalRegistry from './ExternalRegistry';
import ZolaRegistry from './ZolaRegistry';

// styles
import { Container, EmptyStateMessage, RegistryContent } from './Registry.styles';
import { ExternalRegistrySection } from './RegistrySP.styles';

type RegistrySPProps = {
  pageData?: WCmsWeddingRegistryPageView;
};

const RegistrySP: React.FC<RegistrySPProps> = ({ pageData }) => {
  const {
    state: {
      components: { styleCmsEntityBodyFont },
      inPreview,
      wedding,
    },
  } = useWebsiteThemeContext();

  const userContext = useAppSelector(getUserContext);

  const {
    description,
    header_image_id,
    header_image_url,
    title,
    wedding_registries,
    zola_registry,
    images,
  } = pageData || {};

  const cmsHeaderImgUrl = getNonHomeCmsHeroImg(images);
  const { isMobileDevice } = useAnimationContext();

  const isGuestViewing = userContext?.registry_ids?.[0] !== zola_registry?.registry_object_id;
  const showExternalRegistries = wedding_registries && wedding_registries.length > 0;
  // @ts-expect-error ts-migrate(2551) FIXME: Property 'public' does not exist on type 'WCmsZola...
  const showZolaRegistry = Boolean(zola_registry?.public || inPreview);

  const showEmptyStateMessage = !(showZolaRegistry || showExternalRegistries) && !description;
  const StyledEmptyStateMessage = styleCmsEntityBodyFont(EmptyStateMessage);

  const isSinglePageLayout =
    wedding?.public_theme_v2?.layout_type ===
    (('SINGLE_PAGE' as unknown) as WPublicThemeV2View.LayoutTypeEnum);
  const animationIndex = isSinglePageLayout || isMobileDevice ? 1 : 0;

  return (
    <Container data-testid="RegistrySP">
      <TitleImgDesc
        alt={header_image_id}
        description={description}
        title={title}
        url={cmsHeaderImgUrl || header_image_url}
      />
      <RegistryContent>
        {showZolaRegistry && (
          <ZolaRegistry isGuestViewing={isGuestViewing} zolaRegistry={zola_registry} isSinglePage />
        )}
        {showExternalRegistries && (
          <ExternalRegistrySection>
            {(wedding_registries || []).map((reg, i) => (
              <ExternalRegistry
                description={reg.description}
                id={`other-registry-${i}`}
                key={reg.id}
                name={reg.name}
                url={reg.url}
                isSinglePage
              />
            ))}
          </ExternalRegistrySection>
        )}
        {showEmptyStateMessage && (
          <PageAnimationWrapper index={animationIndex}>
            <StyledEmptyStateMessage>
              We&apos;ll post our registry here when we&apos;re finished building it. Thank you in
              advance!
            </StyledEmptyStateMessage>
          </PageAnimationWrapper>
        )}
      </RegistryContent>
    </Container>
  );
};

export default RegistrySP;
